<template>
    <div class="editOver-container">
        <CSDialog
                class="editOver-container"
                :dialogTitle="dialogTitle"
                dialogWidth="748px"
                :dialogVisible="addRoomVisible"
                @onClose="addRoomVisible = false"
                @onConfirm="editRoom"
                dialog-confirm-btn-text="保存"
                @click="
        allRoomBtn
      "
        >
            <div slot="dialog-content" class="room-form">
                <div class="field-item">
                    <label class="field-item-label">房间号</label>
                    <div class="field-item-content">
                        <input
                                :disabled="disableF"
                                v-model="editRoomInfo.roomNo"
                                type="text"
                                placeholder="限10位"
                                maxlength="10"
                        />
                    </div>
                </div>

                <div class="field-item">
                    <label class="field-item-label"> 所在楼栋 </label>
                    <div class="field-item-content">
                        <CSSelect height="40px" iWidth="36px">
                            <select
                                    :disabled="disableF"
                                    v-model="editRoomInfo.buildingId"
                                    @change="changeBuilding"
                                    :style="disableF?`background:rgba(170, 170, 170,0.1);color: #999999`:`color: #999999`"
                            >
                                <option value="">请选择</option>
                                <option
                                        v-for="building in buildings"
                                        :key="building.code"
                                        :value="building.code"
                                >
                                    {{ building.name }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="field-item">
                    <template v-if="dialogTitle != '修改房间'">
                        <label class="field-item-label"> 所在楼层</label>
                        <div class="field-item-content">
                            <CSSelect height="40px" i-width="36px">
                                <select v-model="editRoomInfo.floor" style="color: #999999">
                                    <option value="">请选择</option>
                                    <option v-for="floor in floorSpace" :value="floor" :key="floor">
                                        {{ floor }}
                                    </option>
                                </select>
                            </CSSelect>
                        </div>
                    </template>
                    <template v-if="dialogTitle == '修改房间'">
                        <label class="field-item-label"> 所在楼层 </label>
                        <div class="field-item-content">
                            <CSSelect height="40px" i-width="36px">
                                <input
                                        :disabled="disableF"
                                        v-model="editRoomInfo.floor"
                                        type="text"
                                        placeholder="请选择"
                                        maxlength="10"
                                        style="width: 195px;border: none"
                                />
                            </CSSelect>
                        </div>
                    </template>
                </div>
                <div class="field-item">
                    <label class="field-item-label"> 建筑面积(㎡) </label>
                    <div class="field-item-content">
                        <input v-model="editRoomInfo.space" placeholder="限数字"/>
                    </div>
                </div>
                <div class="field-item">
                    <label class="field-item-label">
                        租赁单价(元/㎡/天) </label>
                    <div class="field-item-content">
                        <input v-model="editRoomInfo.price" placeholder="限数字"/>
                    </div>
                </div>

                <div class="field-item" style="font-size: 0">
                    <template v-if="!(parentRoomId && parentRoomId > 0)">
                        <label class="field-item-label"> 是否分割</label>
                        <div class="field-item-content">
                            <CSRadio
                                    v-model="editRoomInfo.isDivision"
                                    :items="division"
                            />
                        </div>
                        <div class="field-item-content"
                             style="color: #999;font-size: 14px;margin-top: 10px;margin-bottom: 22px">
                            <label class="field-item-label"> </label>
                            <div style="display: inline-block;width: 420px" v-if="editRoomInfo.isDivision != 1">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-menua-zu92"></use>
                                </svg>
                                <span v-if="editRoomInfo.isDivision == 1">
                                选择不分割时，该房间下级分割房间的设置不生效，公摊以该房间设置为准。
                            统计租赁面积时，不统计下级所属房间。
                            </span>
                                <span v-else>
                                选择分割房间后，关联的分割房间不再产生水电公摊账单。统计租赁面积时，只统计下级所属房间。
                            </span>

                            </div>
                            <div class="field-item" v-if="editRoomInfo.isDivision == 0" style="margin-bottom: 0;margin-top: 30px">
                                <div class="field-item-content">
                                    <div class="field-item" style="margin-bottom: 0">

                                        <label class="field-item-label" style="color: black">分割房间</label>
                                        <AllRoomSelectOwner
                                                :divisionRoom='true'
                                                @change-rooms="changeContractRooms"
                                                :selectedRooms="roomIds"
                                                :space="+this.editRoomInfo.space"
                                                :isSelectedRooms="isSelectedRooms"
                                                :checkedRoomNames="checkedRoomNames"
                                                style="display: inline-block">
                                        </AllRoomSelectOwner>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field-item" v-if="editRoomInfo.isDivision == 0">

                            <label class="field-item-label">剩余可分割面积</label>
                            <div class="field-item-content">
                                {{measure?measure:'-'}}
                            </div>
                        </div>
                    </template>


                    <div >
                        <label class="field-item-label" style="line-height: 32px;margin-top: 2px">水电公摊承担方</label>
                        <div class="field-item-content lable-coin pageHomeWW" style="width: 430px;vertical-align: top"
                             v-if="dialogTitle == '添加房间'">
                            <CSRadio
                                    v-model="editRoomInfo.waterAndElectricity"
                                    :items="{ 0: `水电公摊承担公司（${company?company:'未设置'}）`, 1: '暂不确定',2:'指定企业' }"
                            />
                        </div>
                        <div class="field-item-content lable-coin pageHomeWW" style="width: 430px;vertical-align: top"
                             v-else-if="dialogTitle == '修改房间'&&totalName">
                            <CSRadio
                                    v-model="editRoomInfo.waterAndElectricity"
                                    :items="{ 0: `水电公摊承担公司（${company?company:'未设置'}）`, 1: '暂不确定',2:'指定企业' }"
                            />
                        </div>
                        <div class="field-item-content lable-coin" style="width: 430px;" v-else>
                            {{editRoomInfo.waterAndElectricity ==
                            2?hydropowerCompanyName:waterAndElectricityList[editRoomInfo.waterAndElectricity]}}
                        </div>
                    </div>


                    <div style="margin-top: 22px;position: relative"
                         v-if="editRoomInfo.waterAndElectricity == 2&&(dialogTitle == '添加房间' || totalName)">
                        <label class="field-item-label" style="line-height: 32px"></label>
                        <div class="field-item-content lable-coin" style="width: 430px;vertical-align: top">
                            <CSSelect height="40px" iWidth="36px" style="margin-bottom: 10px;">
                                <div
                                        style="
                                          font-size: 24px;
                                          color: #999;
                                          margin-left: 10px;
                                          width: 179px;
                                        "
                                        @click.stop="tenantVisible = !tenantVisible"
                                >
                                    <span v-if="!hydropowerCompanyName">请选择</span>
                                    <div v-else style="color: black;white-space: nowrap">{{hydropowerCompanyName}}</div>
                                </div>
                                <TreePanel v-show="tenantVisible" width="310px" style="left: 248px">
                                    <div>
                                        <input
                                                type="text"
                                                placeholder="搜索"
                                                style="
                                  width: 200px;
                                  height: 40px;
                                  padding-left: 10px;
                                  font-size: 20px;
                                  border: 1px solid #999;
                                  border-radius: 4px;
                                  vertical-align: middle;
                                "
                                                v-model="keyword"
                                        />
                                        <button
                                                type="button"
                                                class="btn btn-primary"
                                                style="
                                                  margin-left: 10px;
                                                  width: 70px;
                                                  height: 40px;
                                                  font-size: 24px;
                                                  padding: 0 0 2px 0;
                                                  vertical-align: middle;
                                                "
                                                @click="queryTenant"
                                        >
                                            查询
                                        </button>
                                    </div>
                                    <div style="overflow-y: auto; font-size: 20px">
                                        <div
                                                v-for="lessee in lesseeList"
                                                :key="lessee.id"
                                                style="border-bottom: 1px solid #f0f0f0"
                                                @click="addCompanyName(lessee.companyName, lessee.id)"
                                        >
                                            <div style="margin: 5px 10px">{{ lessee.companyName }}</div>
                                            <div style="margin: 5px 10px">
                                                <span>{{ lessee.deputyName }}</span>
                                                <span>{{ " 【" + lessee.deputyPhone + "】" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </TreePanel>
                            </CSSelect>
                        </div>
                    </div>
                </div>


                <div class="field-item">
                    <template v-if="dialogTitle != '修改房间'">
                        <label class="field-item-label"> 水表 </label>
                        <div
                                class="field-item-content"
                                style="position: relative;"
                                @click.stop="
                                  () => {
                                    wrateVisible = !wrateVisible;
                                    electricVisible = false;
                                  }
                                "
                        >
                            <CSSelect height="40px" i-width="36px">
                                <div
                                        :style="{ width: '196px', padding: '0 6px' }"
                                        class="field-font"
                                >
                                    <template v-if="editRoomInfo.water.length > 0"
                                    ><span style="color: black;font-size: inherit"
                                    >已选择水表{{ editRoomInfo.water.length }}个</span
                                    ></template
                                    >
                                    <template v-else>
                                        <option value="" style="font-size: inherit">请选择</option>
                                    </template>
                                </div>
                                <div>
                                    <TreePanel
                                            v-show="wrateVisible"
                                            style="z-index: 100; overflow: hidden"
                                    >
                                        <div>
                                            <div class="location-search">
                                                <input
                                                        type="text"
                                                        placeholder="搜索水表编号"
                                                        class="location-input"
                                                        style="width: 260px"
                                                        v-model="selectInput"
                                                />
                                                <button
                                                        class="btn btn-primary font"
                                                        @click="getAllWaterMeters"
                                                >
                                                    查询
                                                </button>
                                            </div>
                                        </div>
                                        <EditWaterEven
                                                :tree="wrateVisibleList"
                                                @click="changeStaff"
                                                textName="imei"
                                                :showCheckbox="true"
                                                style="height: 100%; overflow-y: scroll"
                                        />
                                    </TreePanel>
                                </div>
                            </CSSelect>
                        </div>
                    </template>
                </div>

                <div class="field-item">
                    <template v-if="dialogTitle != '修改房间'">
                        <label class="field-item-label"> 电表 </label>
                        <div
                                class="field-item-content"
                                style="position: relative"
                                @click.stop="
                              () => {
                                electricVisible = !electricVisible;
                                wrateVisible = false;
                              }
                            "
                        >
                            <CSSelect height="40px" i-width="36px">
                                <div
                                        :style="{ width: '196px', padding: '0 6px' }"
                                        class="field-font"
                                >
                                    <template v-if="editRoomInfo.electricity.length > 0"><span
                                            style="color: black;font-size: inherit">已选择电表{{ editRoomInfo.electricity.length }}个</span>
                                    </template>
                                    <template v-else>
                                        <option value="" style="font-size: inherit">请选择</option>
                                    </template>
                                </div>
                                <div>
                                    <TreePanel v-show="electricVisible" style="z-index: 100">
                                        <div>
                                            <div class="location-search">
                                                <input
                                                        type="text"
                                                        placeholder="搜索电表编号"
                                                        class="location-input"
                                                        style="width: 260px"
                                                        v-model="selectInput"
                                                />
                                                <button
                                                        class="btn btn-primary font"
                                                        @click="getElectricityMeterList"
                                                >
                                                    查询
                                                </button>
                                            </div>
                                        </div>
                                        <EditWaterEven
                                                :tree="electricVisibleList"
                                                @click="changeStaffElectric"
                                                textName="imei"
                                                :showCheckbox="true"
                                                style="height: 100%; overflow-y: scroll"
                                        />
                                    </TreePanel>
                                </div>
                            </CSSelect>
                        </div>
                    </template>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
    import {
        createRoomUrl,
        editRoomUrl,
        queryBuildingUrl,
        getAllWaterMetersUrl,
        isHydropowerBindingUrl,
        queryWaterStateWUrl,
        selectRecordUrl,
        queryISNotDivisionRoomUrl,
        selectWaterMeterRelevanceUrl, queryTenantUrl, queryMeterGroupUrl, querySharedConfigUrl,
    } from "@/requestUrl";
    import TreePanel from "@/components/common/TreePanel";
    import EditWaterEven from "@/components/EditWaterEven";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";
    import {HYDROPOWER} from "@/constant";
    import {cloneDeep} from "lodash";
    import {BigNumber} from "@/utils/bignumber.js";
    import CSRadio from "@/components/common/CSRadio";
    import AllRoomSelectOwner from "@/components/AllRoomSelectOwner";

    export default {
        props: {
            totalName: {
                default: false
            }
        },
        components: {
            CSSelect,
            CSDialog,
            EditWaterEven,
            TreePanel,
            CSRadio,
            AllRoomSelectOwner
        },
        data() {
            return {
                computedIndex: 1,
                parentRoomId: null,
                iSNotDivisionRoomList: [],
                divisionVisible: false,//分割房间
                tenantVisible: false,
                lesseeList: [],
                keyword: '',
                checkedRoomNames: [],
                isSelectedRooms: [],
                roomIds: [],
                roomNoGG: "",
                roomNoKK: "",
                selectInput: "", //搜索表
                waterKey: [], //水表id
                electricVisible: false,
                wrateVisible: false, //随表下拉显示
                wrateVisibleList: [], //水表列表
                electricVisibleList: [], //水表列表
                dialogTitle: "添加房间",
                floorSpace: [], // 楼层范围
                measure: '',//剩余可分割面积
                company: '',
                editRoomInfo: {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    buildingId: "",
                    floor: "",
                    roomNo: "",
                    space: "",
                    price: "",
                    water: [],
                    electricity: [],
                    isDivision: 0,//是否分割房间
                    waterAndElectricity: 0,
                    builtUpAread: [],
                    roomIds: [],//房间ID
                    hydropowerCompany: ''//企业ID
                },
                hydropowerCompanyName: '',
                buildings: [],
                addRoomVisible: false,
            };
        },

        computed: {
            //是否分割房间
            division() {
                //:items="parentRoomId&&parentRoomId>0?{1: '不分割'}:{ 0: '分割', 1: '不分割' }"
                if (this.disableF && this.computedIndex) {
                    if (this.parentRoomId && this.parentRoomId > 0) {
                        return {1: '不分割'}
                    } else if (this.editRoomInfo.roomIds && this.editRoomInfo.roomIds.length > 0) {
                        if (this.editRoomInfo.isDivision == 1) {
                            return {1: '不分割'}
                        }
                        return {0: '分割'}
                    } else {
                        return {0: '分割', 1: '不分割'}
                    }
                } else {
                    return {0: '分割', 1: '不分割'}
                }
            },
            disableF() {
                return this.dialogTitle == '修改房间';
            },
            waterAndElectricityList() {
                if (this.company) {
                    return [`水电公摊承担公司（${this.company}）`, '暂不确定', '指定企业']
                }
                return ['水电公摊承担公司（未设置）', '暂不确定', '指定企业']
            }
        },
        created() {
            this.getQuerySharedConfig();
        },
        activated() {
            //监听
            this.addEventBtn();
        },
        async mounted() {
            //监听
            this.addEventBtn();
            this.queryISNotDivisionRoom();
            this.queryTenant();

        },
        watch: {
            'editRoomInfo.roomIds': () => {
                console.log('变化llllllll');
            }
        },
        methods: {

            //选择房间
            changeContractRooms(roomInfo) {
                console.log('选择');

                const {roomIds, rooms} = roomInfo;
                this.contractRooms = roomIds.map((val) => {
                    return {roomId: val};
                });
                //BigNumber
                let j = new BigNumber(this.editRoomInfo.space)
                this.measure = j.minus(rooms.reduce((prev, curr) => {
                    let x = new BigNumber(curr.roomSpace);
                    return x.plus(prev);
                }, 0));

                //回显房间列表
                this.selectedRooms = rooms.map((item) => {
                    return {
                        buildingId: item.buildingId,
                        buildingName: item.buildingName,
                        floor: item.floor,
                        roomId: item.roomId,
                        roomName: item.roomName,
                        area: item.area,
                    };
                });

                //选中的房间列表
                this.editRoomInfo.roomIds = rooms.map(item => {
                    return item.roomId;
                })
                this.computedIndex++;
            },
            //事件监听
            addEventBtn() {
                this.$vc.on("editRoom", "openEditRoomModal", (_room) => {
                    console.log(_room);
                    this.getAllWaterMeters();
                    this.getElectricityMeterList();
                    try {
                        if (_room.title) {
                            this.dialogTitle = _room.title;
                        }

                    } catch {
                        this.dialogTitle = '添加房间';
                    }

                    this.refreshEditRoomInfo();
                    this.getBuildings();

                    if (_room?.title == '修改房间') {
                        console.log('_room');
                        console.log(_room);
                        this.parentRoomId = _room.parentRoomId || "";
                        this.checkedRoomNames = [];
                        this.editRoomInfo.roomId = _room.roomId;
                        this.editRoomInfo.roomNo = _room.roomNo;
                        this.editRoomInfo.space = _room.space;
                        this.editRoomInfo.price = _room.price / 100;
                        this.editRoomInfo.buildingId = _room.buildingId;
                        this.editRoomInfo.floor = _room.floor;
                        this.roomNoKK = _room.waterCount ? '已选择水表' + _room.waterCount + '个' : "";
                        this.roomNoGG = _room.electricityCount ? '已选择电表' + _room.electricityCount + '个' : '';
                        this.editRoomInfo.isDivision = _room.isDivision;
                        if (_room.divisionRoomId) {
                            this.editRoomInfo.roomIds = _room.divisionRoomId.split(',');
                            this.$nextTick(() => {
                                console.log('22222222222222')
                                console.log(this.editRoomInfo.roomIds);
                                this.roomIds = this.editRoomInfo.roomIds;
                            })

                        } else {
                            this.roomIds = [];
                        }
                        this.editRoomInfo.waterAndElectricity = _room.waterAndElectricity;
                        this.hydropowerCompany = _room.hydropowerCompany;
                        this.hydropowerCompanyName = _room.hydropowerCompanyName;

                    } else {
                        this.checkedRoomNames = [];
                        this.parentRoomId = null;
                        this.measure = null;
                    }
                    this.addRoomVisible = true;
                });
            },
            //查询水电费公摊设置
            getQuerySharedConfig() {
                this.$fly.get(querySharedConfigUrl, {
                    regionCode: this.$vc.getCurrentRegion().code
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.company = res.data.equallySharedCompanyName || "";
                })
            },
            //选择房间
            // selectRoom(item) {
            //     setTimeout(() => {
            //         let hostM = this.measure;
            //
            //         this.measure = this.editRoomInfo.space - this.iSNotDivisionRoomList.filter(item => {
            //             return item.checkbox
            //         }).reduce((prev, curr) => {
            //             return prev + curr.space
            //         }, 0)
            //
            //
            //         this.measure = this.measure.toFixed(2);
            //         if (this.measure < 0) {
            //             this.measure = hostM;
            //             this.$vc.toast('所选房间面积不可超过所属房间总面积');
            //             this.iSNotDivisionRoomList = this.iSNotDivisionRoomList.map(item => {
            //                 if (this.editRoomInfo.roomIds) {
            //                     for (var i = 0; i < this.editRoomInfo.roomIds.length; i++) {
            //                         if (item.id == this.editRoomInfo.roomIds[i]) {
            //                             return {
            //                                 ...item,
            //                                 checkbox: true
            //                             }
            //                         }
            //                     }
            //                 }
            //
            //                 return {
            //                     ...item,
            //                     checkbox: false
            //                 }
            //             })
            //             return
            //         }
            //         this.editRoomInfo.roomIds = this.iSNotDivisionRoomList.filter(item => {
            //             return item.checkbox
            //         }).map(item => {
            //             return item.id
            //         }) || []
            //
            //         console.log(this.editRoomInfo.roomIds);
            //     }, 200)
            //
            // },
            //查询不分割且未被关联的房间
            queryISNotDivisionRoom() {
                this.$fly.get(queryISNotDivisionRoomUrl, {
                    regionCode: this.$vc.getCurrentRegion().code
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    res.data = res.data.map(item => {
                        return {
                            ...item,
                            checkbox: false,
                            name: item.buildingName + '-' + item.floor + '层' + '-' + item.roomNo
                        }
                    })
                    console.log('分割房间');
                    console.log(res.data);
                    this.iSNotDivisionRoomList = res.data;
                })
            },
            // 点击下拉框选中
            addCompanyName(companyName, lessedId) {
                console.log('选在');
                // if (this.addPanelInfo.lesseeList.includes(lessedId)) {
                //     this.$vc.toast("请不要重复选中");
                //     return;
                // }
                // this.companyNameList.push(companyName);
                // this.addPanelInfo.lesseeList.push(lessedId);
                this.editRoomInfo.hydropowerCompany = lessedId;
                this.hydropowerCompanyName = companyName;
                this.tenantVisible = false;
            },
            //查询企业
            queryTenant() {
                this.$fly
                    .post(queryTenantUrl, {
                        regionId: this.$vc.getCurrentRegion().code,
                        keyword: this.keyword,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.lesseeList = res.data.datas;
                    });
            },
            allRoomBtn() {
                this.electricVisible = false;
                this.wrateVisible = false;
                this.tenantVisible = false;
                this.divisionVisible = false;
                this.$vc.emit('allRoomSelectContract', 'selcet', {});
            },

            async changeStaffElectric({key, source}) {
                var isS = await this.isHydropowerBinding(
                    source[key].id,
                    HYDROPOWER.WATT_HOUR_METER
                );
                if (isS.data != "") {
                    source[key].checked = false;
                    source[key].allowCheck = false;
                    this.$vc.message(`该表已经与${isS.data}关联，无法选中`);
                    return;
                }
                const iSRes = await this.$fly.get(selectRecordUrl, {
                    id: source[key].id,
                });
                if (iSRes.code != 0) {
                    return;
                }
                if (source[key].checked) {
                    source[key].checked = false;
                    this.editRoomInfo.electricity = this.editRoomInfo.electricity.filter(
                        (item) => {
                            return item.checked;
                        }
                    );
                    return;
                }

                source[key].checked = true;
                this.editRoomInfo.electricity.push(source[key]);
            },
            //查询水电表是否被绑定
            isHydropowerBinding(ids, types) {
                return this.$fly
                    .get(isHydropowerBindingUrl, {
                        id: ids,
                        type: types,
                    })
                    .then((res) => {
                        return res;
                    });
            },
            //下拉框选中状态变化
            async changeStaff({key, source}) {
                var isS = await this.isHydropowerBinding(
                    source[key].id,
                    HYDROPOWER.WATER_METER
                );
                if (isS.data != "") {
                    source[key].checked = false;
                    source[key].allowCheck = false;
                    this.$vc.message(`该表已经与${isS.data}关联，无法选中`);
                    return;
                }
                const iSRes = await this.$fly.get(queryWaterStateWUrl, {
                    id: source[key].id,
                });
                if (iSRes.code != 0) {
                    return;
                }
                if (source[key].checked) {
                    source[key].checked = false;
                    this.editRoomInfo.water = this.editRoomInfo.water.filter((item) => {
                        return item.checked;
                    });
                    return;
                }
                source[key].checked = true;
                this.editRoomInfo.water.push(source[key]);
            },
            /**
             * 修改选中的楼栋
             * @param {Object} e
             * */
            changeBuilding(e) {
                console.log(1);
                if (e.target.selectedIndex > 0) {
                    console.log(2);
                    const building = this.buildings[e.target.selectedIndex - 1];
                    let floorSpace = [];
                    for (
                        let i = -(building.undergroundFloor || 0);
                        i <= building.aboveGroundFloor;
                        i++
                    ) {
                        if (i !== 0) {
                            floorSpace.push(i);
                        }
                    }
                    console.log(floorSpace);
                    this.floorSpace = floorSpace;
                    return;
                }
                console.log(3);
                this.floorSpace = [];
            },
            // 获取楼栋列表
            getBuildings() {
                this.$fly
                    .post(queryBuildingUrl, {
                        id: "",
                        code: "",
                        regionCode: this.$vc.getCurrentRegion().code,
                        name: "",
                        state: 1,
                    })
                    .then((res) => {
                        if (res?.code !== 0) {
                            this.buildings = [];
                            return;
                        }
                        this.buildings = res.data.datas;


                    });
            },
            isSelect(wrateVisibleList, water) {
                for (var j = 0; j < water.length; j++) {
                    if (wrateVisibleList.imei == water[j].imei && water[j].checked) {
                        return true;
                    }
                }

                return false;
            },
            //获取水表列表
            getAllWaterMeters() {
                this.$fly
                    .post(getAllWaterMetersUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域code
                        search: this.selectInput,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.wrateVisibleList = res.data.map((item, index) => {
                            return {
                                ...item,
                                checked: this.isSelect(res.data[index], this.editRoomInfo.water)
                                    ? true
                                    : false,
                                allowCheck: true,
                            };
                        });
                    });

                ///保存塞选项的状态
                this.editRoomInfo.water = [
                    ...this.editRoomInfo.water,
                    ...this.wrateVisibleList.filter((item) => {
                        return item.checked;
                    }),
                ];
                var waterList = [];
                var waterListImei = [];
                for (var i = 0; i < this.editRoomInfo.water.length; i++) {
                    if (waterList.indexOf(this.editRoomInfo.water[i].imei) == -1) {
                        waterList.push(this.editRoomInfo.water[i].imei);
                        waterListImei.push(this.editRoomInfo.water[i]);
                    }
                }
                this.editRoomInfo.water = waterListImei;
            },
            // 获取电表列表
            getElectricityMeterList() {
                this.$fly
                    .post(selectWaterMeterRelevanceUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域code
                        search: this.selectInput,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.electricVisibleList = res.data.map((item, index) => {
                            return {
                                ...item,
                                checked: this.isSelect(
                                    res.data[index],
                                    this.editRoomInfo.electricity
                                )
                                    ? true
                                    : false,
                                allowCheck: true,
                            };
                        });
                    });

                ///保存塞选项的状态
                this.editRoomInfo.electricity = [
                    ...this.editRoomInfo.electricity,
                    ...this.electricVisibleList.filter((item) => {
                        return item.checked;
                    }),
                ];
                var waterList = [];
                var waterListImei = [];
                for (var i = 0; i < this.editRoomInfo.electricity.length; i++) {
                    if (waterList.indexOf(this.editRoomInfo.electricity[i].imei) == -1) {
                        waterList.push(this.editRoomInfo.electricity[i].imei);
                        waterListImei.push(this.editRoomInfo.electricity[i]);
                    }
                }
                this.editRoomInfo.electricity = waterListImei;
            },
            editRoom: function () {
                //如果为指定企业
                if (this.editRoomInfo.waterAndElectricity !== 2) {
                    this.editRoomInfo.hydropowerCompany = null
                }
                if (this.editRoomInfo.roomIds && this.editRoomInfo.roomIds.length > 0) {
                    this.editRoomInfo.roomIds = this.editRoomInfo.roomIds.toString();
                }
                if (this.editRoomInfo.space - this.measure < 0) {
                    this.$vc.toast('所选房间面积不可超过所属房间总面积');
                    return;
                }
                if (this.dialogTitle == '修改房间') {
                    this.$fly.post(editRoomUrl, {
                        id: this.editRoomInfo.roomId,
                        price: this.editRoomInfo.price,
                        space: this.editRoomInfo.space,
                        regionId: this.$vc.getCurrentRegion().communityId,
                        buildingId: this.editRoomInfo.buildingId,

                        floor: this.editRoomInfo.floor,
                        roomNo: this.editRoomInfo.roomNo,
                        isDivision: this.editRoomInfo.isDivision,
                        roomIds: this.editRoomInfo.roomIds,
                        waterAndElectricity: this.editRoomInfo.waterAndElectricity,
                        hydropowerCompany: this.editRoomInfo.hydropowerCompany
                    }).then(res => {
                        if (res.code != 0) {
                            this.$vc.toast(res.msg);
                            return
                        }
                        this.$vc.toast("修改成功");
                        this.addRoomVisible = false;
                        this.$vc.emit("room", "listRoom", null);
                    })
                    return;
                }
                this.editRoomInfo.water = this.editRoomInfo.water.map((item) => {
                    return {id: item.id, no: item.imei};
                });
                const params = cloneDeep(this.editRoomInfo);
                if (params.price.includes(".") && params.price.split(".")[1].length > 2) {
                    this.$vc.message("可保留两位小数");
                    return;
                }
                if (params.space.includes(".") && params.space.split(".")[1].length > 2) {
                    this.$vc.message("可保留两位小数");
                    return;
                }
                params.space = +params.space;
                // params.price = params.price * 100;
                for (var i = 0; i < params.electricity.length; i++) {
                    delete params.electricity[i].allowCheck;
                    delete params.electricity[i].checked;
                    params.electricity[i].no = params.electricity[i].imei;
                    delete params.electricity[i].imei;
                }
                this.$fly
                    .post(createRoomUrl, this.$vc.removeNullProperty(params))
                    .then((res) => {
                        if (res.code != 0) {
                            this.$vc.toast(res.msg);
                            return;
                        }
                        this.$vc.toast("添加成功");
                        this.addRoomVisible = false;
                        this.$vc.emit("room", "listRoom", null);
                    });
            },
            refreshEditRoomInfo: function () {
                this.editRoomInfo = {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    buildingId: "",
                    floor: "",
                    roomNo: "",
                    space: "",
                    price: "",
                    water: [],
                    electricity: [],
                    isDivision: 0,
                    waterAndElectricity: 0
                };
            },

        },
    };
</script>
<style lang="stylus" scoped>
    .room-form {
        padding: 27px 30px 10px;

        .field-item {
            margin-bottom: 22px;
            font-size: 24px;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }

        .field-font {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #999999;
        }
    }

    .location-search {
        width: 100%;
        display: flex;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 30px;
        height: 40px;
        box-sizing: border-box;
    }

    .font {
        font-size: 20px;
        vertical-align: middle;
        margin-left: 30px;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    }

    .selectDive {
        padding: 0;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        display: inline-block;
        border-radius: 3px;
        margin-right: 5px;
        position: relative;
        background-image: "../../assets/未选中.png"
    }

    input[type='checkbox'] {
        width: 0px !important
        display inline-block
        opacity 1
        margin-right 25px
        height 20px !important
    }

    .roomli {
        display flex
        align-items center
    }

    .roomMove {
        cursor pointer
    }
</style>
<style>
    .lable-coin .cs-radio-group div:nth-child(3) {
        margin-top: 15px
    }
    .pageHomeWW .cs-radio-group div:nth-child(1) {
        display: flex;
        line-height: 35px;
    }
    .pageHomeWW .cs-radio-group div:nth-child(1) label{
        flex: 0 0 auto;
        margin-top: 10px;

    }

</style>
